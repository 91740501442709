import { ElementType } from 'react';
import { Box, Typography, styled, ImageListItem, ImageListItemBar } from '@mui/material';

export const StyledImageListItem = styled(ImageListItem)<{ component?: ElementType }>(({ theme }) => ({
  position: 'relative',
  width: '100%',
  border: '1px solid transparent',
  borderRadius: theme.spacing(0.5),
  overflow: 'hidden',
  boxShadow: theme.shadows[1],
  '&:hover .hoverContainer, &:hover .MuiImageListItemBar-actionIcon': {
    opacity: 1,
  },
  [theme.breakpoints.up('md')]: {
    '&:hover .promptContainer': {
      visibility: 'visible',
    },
  },
}));

export const StyledImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
  background: theme.palette.white.main,
  '& .MuiImageListItemBar-titleWrap': {
    padding: theme.spacing(0.75, 1.25, 0.75, 1.25),
  },
  '& .MuiImageListItemBar-title': {
    ...theme.typography.body1,
    whiteSpace: 'inherit',
  },
  '& .MuiImageListItemBar-actionIcon': {
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      opacity: 1,
    },
  },
  '& .MuiLink-root': {
    color: theme.palette.blue.main,
    textDecoration: 'none',
  },
}));

export const BodyWrapper = styled(Box)({
  position: 'relative',
});

export const BoxHover = styled(Box, {
  shouldForwardProp: prop => prop !== 'isUserFavorite',
})<{ isUserFavorite: boolean }>(({ theme, isUserFavorite }) => ({
  position: 'absolute',
  top: theme.spacing(3.75),
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  opacity: 0,
  zIndex: 2,
  ...(isUserFavorite && {
    opacity: 1,
  }),
  [theme.breakpoints.down('md')]: {
    opacity: 1,
  },
}));

export const BoxFavorite = styled(Box)(({ theme }) => ({
  background: theme.palette.white.main,
  borderTopLeftRadius: theme.spacing(0.5),
  borderBottomLeftRadius: theme.spacing(0.5),
  padding: theme.spacing(0, 0.2),
  display: 'flex',
}));

export const IconButtonWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const PromptContainer = styled('div')<{ showprompt: string }>(({ theme, showprompt }) => ({
  position: 'absolute',
  bottom: theme.spacing(0.75),
  visibility: showprompt === 'true' ? 'visible' : 'hidden',
  flexDirection: 'column',
  backgroundColor: '#4D134B',
  opacity: 0.8,
  padding: theme.spacing(0.75),
  zIndex: 1,
  marginLeft: theme.spacing(0.75),
  width: `calc(100% - ${theme.spacing(1.5)})`,
  maxHeight: `calc(100% - ${theme.spacing(1.5)})`,
}));

export const PromptHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 30,
}));

export const PromptHeaderTypography = styled(Typography)(({ theme }) => ({
  opacity: 0.6,
  color: theme.palette.white.main,
  fontSize: '12px',
}));

export const PromptHeaderButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const PromptBody = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const PromptBodyTypography = styled(Typography)<{ lineclamp: string }>(({ theme, lineclamp }) => ({
  display: '-webkit-box',
  WebkitLineClamp: parseInt(lineclamp),
  WebkitBoxOrient: 'vertical',
  color: theme.palette.white.main,
  fontSize: '12px',
  overflow: 'hidden',
  lineHeight: '18px',
}));

export const FABContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 19,
  right: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  zIndex: 2,
}));
