import classNames from 'classnames';

import { FavoriteIconButtonProps } from './FavoriteIconButton.types';
import * as S from './FavoriteIconButton.styles';

export const FavoriteIconButton = (props: FavoriteIconButtonProps) => {
  const { isAdded, children, onClick } = props;

  return (
    <S.FavoriteIconButton
      className={classNames(isAdded && 'scale', 'cfFavoriteIconButton')}
      component="button"
      underline="none"
      onClick={onClick}
    >
      <S.FavoriteIcon icon={[isAdded ? 'fas' : 'far', 'heart']} />
      <S.FavoriteCounter>{children}</S.FavoriteCounter>
    </S.FavoriteIconButton>
  );
};
