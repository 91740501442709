import { Typography } from '@mui/material';
import { Button, Modal, ModalProps } from 'cf-ui';
import merge from 'lodash.merge';
import { Fragment, memo } from 'react';

import sparkUpsellImage from 'assets/banners/ai-pop-up-spark-upsell.jpeg';
import { useFormatMessage } from 'utilities/i18n';
import { useCurrency } from 'hooks/useCurrency/useCurrency';
import { usePriceCalculation } from 'hooks/usePriceCalculation';
import { AppRoute } from 'components/app/App.types';

import * as S from './HideSparkPromptModal.styles';

export const HideSparkPromptModal = memo(function HideSparkPromptModal({ sx, onClose, children, ...rest }: ModalProps) {
  return (
    <Modal
      {...rest}
      onClose={onClose}
      contentProps={{
        sx: { padding: 0 },
      }}
      sx={merge({ '& .MuiPaper-root': { padding: 0, maxWidth: 934, width: `calc(100% - 64px)` } }, sx)}
    >
      <HideSparkPromptContent onClose={onClose} />
    </Modal>
  );
});

function HideSparkPromptContent({ onClose }: Pick<ModalProps, 'onClose'>) {
  const t = useFormatMessage();
  const currencyData = useCurrency();
  const { data } = usePriceCalculation(
    {
      currency: currencyData.activeCurrency.code,
      match: true,
      prices: [{ basePrice: 900 }],
    },
    {
      enabled: currencyData?.activeCurrency?.code && currencyData.activeCurrency.code !== 'USD',
    },
  );

  const currencySymbol = currencyData.activeCurrency.symbol;

  function onDismiss(e: React.MouseEvent) {
    e.preventDefault();
    onClose?.();
  }

  return (
    <S.Container>
      <S.Content>
        <Typography variant="h2">{t('spark_cta_prompt.heading')}</Typography>
        <Typography variant="body1" sx={{ fontSize: 12 }}>
          {t('spark_cta_prompt.copy')}
        </Typography>
        <S.HR />
        <S.List>
          <Typography variant="h3">{t('spark_cta_prompt.list.heading')}</Typography>
          <S.UL>
            {([
              'spark_cta_prompt.list.item_1',
              'spark_cta_prompt.list.item_2',
              'spark_cta_prompt.list.item_3',
              'spark_cta_prompt.list.item_4',
            ] as const).map(text => (
              <S.LI key={text}>
                <S.StyledListIcon icon={['far', 'circle-check']} />
                <Typography>{t(text)}</Typography>
              </S.LI>
            ))}
          </S.UL>
          <Typography>
            {t('spark_cta_prompt.list.copy', {
              values: {
                price: () => `${currencySymbol}${data['900'] ? data['900'] / 100 : 9.0}`,
              },
            })
              .split('\n')
              .map((text, index, array) => (
                <Fragment key={text}>
                  {text}
                  {index + 1 < array.length && <br />}
                </Fragment>
              ))}
          </Typography>
        </S.List>
        <S.Actions>
          <Button sx={{ width: '100%' }} color="secondary" href={AppRoute.FREE_TRIAL}>
            {t('spark_cta_prompt.button')}
          </Button>
          <S.StyledLink href="#" onClick={onDismiss}>
            {t('spark_cta_prompt.dismiss')}
          </S.StyledLink>
        </S.Actions>
      </S.Content>
      <S.StyledImage src={sparkUpsellImage} width={1108} height={1184} alt="" />
    </S.Container>
  );
}
