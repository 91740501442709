import { UseQueryOptions } from '@tanstack/react-query';

import { Currency, PricesToTransformInput, usePriceCalculationQuery, PriceCalculationQuery } from 'generated/graphql';

type PriceCalculationInput = {
  currency: Currency;
  prices: PricesToTransformInput[];
  match: boolean;
};

type PriceCalculationOutput = {
  data: Record<string, number>;
  error?: string | string[];
};

export function usePriceCalculation(
  input: PriceCalculationInput,
  options: UseQueryOptions<PriceCalculationQuery>,
): PriceCalculationOutput {
  const { currency, prices, match } = input;
  const { data } = usePriceCalculationQuery(
    {
      targetCurrency: currency,
      prices: prices,
      match: match || false,
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );

  if (!data) {
    return {
      data: {},
      error: 'No data returned',
    };
  }

  const gqlData = data.getCalculatedPrices;

  if (gqlData.errors) {
    return {
      data: {},
      error: gqlData.errors.map(err => err.message),
    };
  }

  return {
    data:
      gqlData?.data?.prices?.reduce(
        (acc, price) => ({ ...acc, [String(price?.basePrice)]: price?.currencyPrice }),
        {},
      ) || {},
  };
}
