import { styled } from '@mui/material';
import { Typography } from '@mui/material';

import { Link } from 'components/elements/atoms/link/Link';
import { Icon } from 'components/elements/atoms/icon/Icon';

export const FavoriteIconButton = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  border: 0,
  cursor: 'pointer',
  padding: theme.spacing(0.25, 0.5),
  '&.scale': {
    '&:hover > svg': {
      transform: 'scale(1.2)',
    },
  },
}));

export const FavoriteIcon = styled(Icon)(({ theme }) => ({
  fontSize: 18,
  marginRight: theme.spacing(0.375),
  transition: 'transform 0.1s ease-in-out',
  color: theme.palette.red.main,
}));

export const FavoriteCounter = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  marginBottom: theme.spacing(0.125),
  color: theme.palette.red.main,
  fontWeight: theme.typography.fontWeightMedium,
}));
