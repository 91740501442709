import { styled } from '@mui/material';
import Image from 'next/future/image';

import { Icon } from 'components/elements/atoms/icon/Icon';
import { Link } from 'components/elements/atoms/link/Link';

export const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '360px minmax(0, 1fr)',
    gap: 24,
    // this border radius is only for the image,
    // since the modal does have a border radius although
    // it cannot crop it's contents because of the floating close button
    // so the image also bleeds out
    borderBottomRightRadius: 8,
    overflow: 'hidden',
  },
}));

export const HR = styled('hr')(({ theme }) => ({
  height: 1,
  background: theme.palette.divider,
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
  flexDirection: 'column',
  padding: 20,
}));

export const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  flexDirection: 'column',
});

export const List = styled('div')({
  display: 'flex',
  gap: 10,
  flexDirection: 'column',
});

export const UL = styled('ul')({
  display: 'flex',
  gap: 10,
  flexDirection: 'column',
});

export const LI = styled('li')({
  display: 'flex',
  gap: 10,
});

export const StyledListIcon = styled(Icon)({
  marginTop: 4,
});

export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.black.main,
}));

export const StyledImage = styled(Image)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    display: 'block',
    objectFit: 'cover',
    height: '100%',
    aspectRatio: `1108 / 1184`,
  },
}));
