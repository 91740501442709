import React, { SyntheticEvent, useLayoutEffect, useRef, useState } from 'react';
import { getChildrenByType } from 'react-nanny';
import { useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';

import { AppRoute } from 'components/app/App.types';
import { Icon } from 'components/elements/atoms/icon/Icon';
import { IconButton } from 'components/elements/atoms/iconButton/IconButton';
import { Link } from 'components/elements/atoms/link/Link';
import { FavoriteIconButton } from 'components/elements/molecules/favoriteIconButton/FavoriteIconButton';
import { Tooltip } from 'components/elements/molecules/tooltip/Tooltip';
import { HideSparkPromptModal } from 'components/elements/organisms/hideSparkPromptModal/HideSparkPromptModal';
import { PROMPT_TEXT_QUERY_KEY } from 'components/pages/sparkPage/context/CreateSparkPrompt';
import { useHasSparkAccess } from 'hooks/useHasSubscription/useHasSubscription';
import { useFormatMessage } from 'utilities/i18n';
import { CreationType } from 'generated/graphql';
import { FutureImage } from 'components/elements/atoms/image/FutureImage';

import * as S from './SparkFeedCard.styles';
import {
  HiddenPromptContentProps,
  PromptContentProps,
  SparkFeedCardProps,
  SparkFeedPromptActionButtonsProps,
  TooltipText,
} from './SparkFeedCard.types';

export const creationTypeToToolName: Record<CreationType, string> = {
  art: 'CF Spark Art',
  patterns: 'CF Spark Patterns',
  crystalline: 'CF Spark Crystalline',
  sketch: 'CF Spark Sketch',
  imagemix: 'CF Spark ImageMix',
  coloringPage: 'CF Spark Coloring Page',
};

export const creationTypeToAppRoute: Record<CreationType, AppRoute> = {
  art: AppRoute.SPARK_ART_GENERATOR,
  patterns: AppRoute.SPARK_PATTERNS,
  crystalline: AppRoute.SPARK_CRYSTALLINE,
  sketch: AppRoute.SPARK_SKETCH_GENERATOR,
  imagemix: AppRoute.SPARK_IMAGEMIX,
  coloringPage: AppRoute.SPARK_COLORING_PAGE_GENERATOR,
};

function SparkFeedCardRoot({
  authorHref,
  authorName,
  enableFavorite,
  favoriteCount,
  href,
  isUserFavorite,
  image,
  onFavoriteClick,
  onRedirectClick,
  prompt,
  title,
  tooltipText,
  imageWidth,
  imageHeight,
  promptVisibility,
  creationType,
  children,
}: SparkFeedCardProps) {
  const [showPrompt, setShowPrompt] = useState(false);
  const [lineClamp, setLineClamp] = useState(10);
  const [favoriteCounts, setFavoriteCounts] = useState(favoriteCount || 0);
  const [userFavorite, setUserFavorite] = useState(isUserFavorite);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const t = useFormatMessage();
  const elementRef = useRef<HTMLDivElement>(null);

  const fabs = getChildrenByType(children, SparkFeedCardFABContainer);

  useLayoutEffect(() => {
    const elementHeight = elementRef.current?.clientHeight;

    if (elementHeight) {
      //18px = line height
      //30px = top position of favorite box
      //25px = height of favorite box
      //42px = height of prompt header
      //6px = padding bottom
      setLineClamp(Math.trunc((elementHeight - 103) / 18));
    }
  }, []);

  const handleEllipsisClick = () => {
    setShowPrompt(!showPrompt);
  };

  const handleImageClick = (e: React.MouseEvent) => {
    if (href || !isMobile) {
      return;
    }

    e.preventDefault();

    setShowPrompt(!showPrompt);
  };

  const handleFavoriteClick = async () => {
    if (enableFavorite) {
      setFavoriteCounts(prevValue => (!userFavorite ? prevValue + 1 : prevValue - 1));
      setUserFavorite(prevValue => !prevValue);
    }
    try {
      if (onFavoriteClick) {
        await onFavoriteClick();
      }
    } catch {}
  };

  const isPromptVisible = promptVisibility === 'visible' || promptVisibility === 'private';

  return (
    <>
      <S.StyledImageListItem component="div">
        <S.BodyWrapper ref={elementRef}>
          {fabs}
          {onFavoriteClick || enableFavorite ? (
            <S.BoxHover className="hoverContainer" isUserFavorite={userFavorite}>
              <S.BoxFavorite>
                <FavoriteIconButton children={favoriteCounts} onClick={handleFavoriteClick} isAdded={userFavorite} />
              </S.BoxFavorite>
            </S.BoxHover>
          ) : null}
          <Link
            className={classNames('block', Boolean(href) && 'cursor-pointer')}
            href={href}
            target="_blank"
            onClick={handleImageClick}
          >
            <FutureImage
              src={image}
              unoptimized
              alt={title || ''}
              width={imageWidth}
              height={imageHeight}
              className="w-full"
              imageClassName="w-full, h-[auto]"
            />
          </Link>
          <S.PromptContainer data-testid="prompt-container" className="promptContainer" showprompt={`${showPrompt}`}>
            {isPromptVisible ? (
              <PromptContent
                prompt={prompt}
                onRedirectClick={onRedirectClick}
                tooltipText={tooltipText}
                lineClamp={lineClamp}
                promptVisibility={promptVisibility}
                creationType={creationType}
              />
            ) : (
              <HiddenPromptContent lineClamp={lineClamp} />
            )}
          </S.PromptContainer>
        </S.BodyWrapper>

        {authorName !== undefined && authorHref !== undefined && (
          <S.StyledImageListItemBar
            position="below"
            title={t('spark_feed_card.copy', {
              values: {
                author: authorName,
                link: (...c: unknown[]) => (
                  <Link href={authorHref} target="_blank" rel="noopener noreferrer">
                    {c}
                  </Link>
                ),
              },
            })}
            actionIcon={
              <S.IconButtonWrapper>
                <IconButton className="icon" icon="ellipsis" onClick={handleEllipsisClick} iconColor="#403068" />
              </S.IconButtonWrapper>
            }
          />
        )}
      </S.StyledImageListItem>
    </>
  );
}

function SparkFeedCardFABContainer({ children }: { children?: React.ReactNode }) {
  return <S.FABContainer>{children}</S.FABContainer>;
}

const PromptContent = ({
  lineClamp,
  prompt,
  onRedirectClick,
  tooltipText,
  promptVisibility,
  creationType,
}: PromptContentProps) => {
  const t = useFormatMessage();

  return (
    <>
      <S.PromptHeader>
        <S.PromptHeaderTypography as="div">
          {t('spark_feed_card.prompt_header')}
          {promptVisibility === 'visible' && (
            <Tooltip
              style={{ display: 'inline' }}
              disableInteractive
              title={t('spark_feed_card.prompt_visible_tooltip')}
              placement="top"
              variant="primary"
            >
              <Icon style={{ marginLeft: 8, marginRight: 8 }} icon={['fas', 'globe']} />
            </Tooltip>
          )}
        </S.PromptHeaderTypography>
        <SparkFeedPromptActionButtons
          prompt={prompt}
          onRedirectClick={onRedirectClick}
          buttonColor="white.main"
          tooltipText={tooltipText}
          creationType={creationType}
        />
      </S.PromptHeader>
      <S.PromptBody>
        <S.PromptBodyTypography lineclamp={`${lineClamp}`}>{prompt}</S.PromptBodyTypography>
      </S.PromptBody>
    </>
  );
};

const HiddenPromptContent = ({ lineClamp }: HiddenPromptContentProps) => {
  const t = useFormatMessage();
  const hasSparkSubscription = useHasSparkAccess();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onOpenModal(e: React.MouseEvent) {
    e.preventDefault();
    setIsModalOpen(true);
  }

  function onCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <S.PromptHeader>
        <S.PromptHeaderTypography as="div">
          {t('spark_feed_card.prompt_header')}
          <Tooltip
            style={{ display: 'inline' }}
            disableInteractive
            title={t('spark_feed_card.prompt_hidden_tooltip')}
            placement="top"
            variant="primary"
          >
            <Icon style={{ marginLeft: 8, marginRight: 8 }} icon={['fas', 'lock']} />
          </Tooltip>
          {t('spark_feed_card.prompt_header.hidden_prompt')}
        </S.PromptHeaderTypography>
      </S.PromptHeader>
      {!hasSparkSubscription ? (
        <>
          <S.PromptBody>
            <S.PromptBodyTypography lineclamp={`${lineClamp}`}>
              {t('spark_feed_card.hidden_prompt.copy', {
                values: {
                  link: (...children: string[]) => (
                    <Link color="secondary" onClick={onOpenModal}>
                      {children}
                    </Link>
                  ),
                },
              })}
            </S.PromptBodyTypography>
          </S.PromptBody>
          <HideSparkPromptModal isOpened={isModalOpen} onClose={onCloseModal} />
        </>
      ) : null}
    </>
  );
};

export const SparkFeedPromptActionButtons = ({
  prompt,
  onRedirectClick,
  buttonColor,
  tooltipText,
  creationType,
}: SparkFeedPromptActionButtonsProps) => {
  const [copyTooltip, setCopyTooltip] = useState(TooltipText.CTA);
  const t = useFormatMessage();

  const handlePromptCopy = async () => {
    try {
      await navigator.clipboard.writeText(prompt);
      setCopyTooltip(TooltipText.COPIED);
    } catch (err) {}
  };

  const handleRedirectClick = (e: SyntheticEvent) => {
    if (onRedirectClick) {
      e.preventDefault();
      onRedirectClick();
    }
  };

  const copyTooltipText =
    copyTooltip === TooltipText.CTA
      ? tooltipText?.copy || t('spark_feed_card.copy_tooltip')
      : tooltipText?.copied || t('spark_feed_card.copied_tooltip');

  return (
    <S.PromptHeaderButtonsContainer>
      <Tooltip
        disableInteractive
        title={
          tooltipText?.redirect ||
          t('spark_feed_card.redirect_tooltip', {
            values: {
              tool: creationTypeToToolName[creationType],
            },
          })
        }
        placement="top"
        variant="primary"
      >
        <Link
          href={`${creationTypeToAppRoute[creationType]}?${PROMPT_TEXT_QUERY_KEY}=${prompt}`}
          onClick={handleRedirectClick}
          className="flex h-[24px] w-[24px] items-center justify-center"
        >
          <Icon className="icon" icon="external-link" color={buttonColor} />
        </Link>
      </Tooltip>
      <Tooltip
        disableInteractive
        title={copyTooltipText}
        placement="top"
        variant={copyTooltip === TooltipText.CTA ? 'primary' : 'tertiary'}
      >
        <IconButton
          size="small"
          className="icon"
          icon="copy"
          onClick={handlePromptCopy}
          iconColor={buttonColor}
          sx={{ fontSize: '12px' }}
        />
      </Tooltip>
    </S.PromptHeaderButtonsContainer>
  );
};

export const SparkFeedCard = Object.assign(SparkFeedCardRoot, {
  FABContainer: SparkFeedCardFABContainer,
});
