import { PaletteOptions, PaletteColor } from '@mui/material/styles';

import { CommunityContentProduct, CreationType } from 'generated/graphql';

export type PromptVisibility = 'hidden' | 'visible' | 'private';

export type SparkFeedCardProps = {
  authorName?: string;
  authorHref?: string;
  enableFavorite: boolean;
  favoriteCount?: number;
  href?: string;
  isUserFavorite: boolean;
  image: string;
  imageWidth?: string | number;
  imageHeight?: string | number;
  onFavoriteClick?: VoidFunction;
  onRedirectClick?: VoidFunction;
  promptVisibility: PromptVisibility;
  creationType: CreationType;
  prompt: string;
  title?: string;
  tooltipText?: {
    redirect?: string;
    copy?: string;
    copied?: string;
  };
  children?: React.ReactNode;
};

export enum TooltipText {
  COPIED,
  CTA,
}

type ThemeColorString = `${keyof PaletteOptions}.${keyof PaletteColor}`;
type HexColor = `#${string}`;

export type SparkFeedPromptActionButtonsProps = {
  prompt: string;
  onRedirectClick?: VoidFunction;
  buttonColor: ThemeColorString | HexColor;
  tooltipText?: SparkFeedCardProps['tooltipText'];
  creationType: CreationType;
};

export type HiddenPromptContentProps = {
  lineClamp: number;
};

export type PromptContentProps = {
  lineClamp: number;
  onRedirectClick?: VoidFunction;
  tooltipText: SparkFeedCardProps['tooltipText'];
  prompt: string;
  promptVisibility: 'visible' | 'private';
  creationType: CreationType;
};

type SparkFeedCardHandlerProductAuthor = Pick<CommunityContentProduct['author'], 'firstName' | 'lastName' | 'slug'>;

export type SparkFeedCardHandlerProduct = Pick<
  CommunityContentProduct,
  'id' | 'isUserFavorite' | 'imageVariants' | 'url' | 'prompt' | 'title' | 'visibility' | 'creationType'
> & {
  author: SparkFeedCardHandlerProductAuthor;
};
